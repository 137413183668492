import React from "react";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import { Section } from "../components/Section";
import { SEO } from "../components/SEO";
import { ContentRenderer } from "../components/ContentRenderer";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "../components/Link";
import { StyledLink } from "../components/Link/style";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getAuthorLink from "../utils/getAuthorLink";

const PostNavigator = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: white;
  box-sizing: border-box;
  z-index: 1;
  border-top: 1px solid ${(p) => p.theme.borderColor};
`;

const PostNavigatorInner = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 66px;
  max-width: 1140px;
  font-size: 14px;
  > div {
    &:first-child {
      width: 50%;
      padding: 10px 20px;
      box-sizing: border-box;
      svg {
        margin-right: 20px;
      }
    }
    &:last-child {
      width: 50%;
      padding: 10px 20px;
      text-align: right;
      box-sizing: border-box;
      svg {
        margin-left: 20px;
      }
      span {
        margin-left: auto;
      }
    }
    ${StyledLink} {
      color: ${(p) => p.theme.bodyFontColor};
      svg {
        margin-top: auto;
        margin-bottom: auto;
        color: ${(p) => p.theme.red};
      }
      display: flex;
      > span {
        overflow: hidden;
        max-width: calc(100% - 40px);
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const Separator = styled.div`
  height: 30px;
  width: 1px;
  min-width: 1px;
  background: ${(p) => p.theme.borderColor};
  padding: 0;
`;

const BlogPostContent = styled.div`
  padding: 0 20px;
  max-width: 750px;
  margin: 40px auto;
  pre {
    overflow: scroll;
    border: 1px solid #ddd;
    padding: 5px;
  }
  table {
    width: 100%;
    font-size: 12.6px;
    border-collapse: collapse;
    td {
      padding: 5px;
    }
    th,
    td {
      border: 1px solid #ccc;
      padding: 15px;
    }
    tbody {
      tr:nth-child(odd) {
        td {
          background-color: #f7f7f7;
        }
      }
    }
  }
`;

const AuthorLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: ${(p) => p.theme.darkBlue};
  }
`;

const BlogPostTemplate = (props) => {
  const { post, nextPost, previousPost } = props.data;
  const displayFeaturedImage = post.useFeaturedImageAtTopOfBlogPost;

  const showAuthorsAndPublishDate = post.category.title !== "Case study";
  const { headerStats } = post;

  const { footer } = post;

  const resourcesTrail = {
    name: "Resources",
    item: "https://rubric.com/en-us/blog/",
  };
  const postTitleTrail = { name: post.seo.title };

  const breadcrumbTrails = [];
  post.tags.forEach((tag) => {
    breadcrumbTrails.push([
      resourcesTrail,
      {
        name: tag.title,
        item: `https://rubric.com/en-us/blog?t=${tag.slugFriendlyName}`,
      },
      postTitleTrail,
    ]);
  });
  breadcrumbTrails.push([
    resourcesTrail,
    {
      name: post.category.title,
      item: `https://rubric.com/en-us/blog?t=${post.category.slugFriendlyName}`,
    },
    postTitleTrail,
  ]);

  return (
    <Layout hasBottomPageNav>
      <SEO
        title={`${post.seo.title} - Rubric`}
        description={post.seo.description}
        image={post.seo.image?.url}
        url={props.pageContext.url}
        type="article"
        breadcrumbTrails={breadcrumbTrails}
      />
      <Section
        backgroundImage={post.seo.image?.gatsbyImageData}
        backgroundOverlay="Red"
        funkySwooshPosition="Bottom"
        bottomSpacing={displayFeaturedImage ? -30 : 0}
      >
        <div
          style={{
            textAlign: "center",
            padding: "0 50px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ maxWidth: 750, margin: "0 auto" }}>
            {post.category.title.toUpperCase()}
          </div>
          <h1
            style={{
              maxWidth: 750,
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {post.title}
          </h1>
          {!!headerStats?.length && (
            <div
              style={{
                display: "flex",
                maxWidth: 1040,
                margin: "0 auto 20px auto",
                flexWrap: "wrap",
              }}
            >
              {headerStats.map((stat) => (
                <div
                  key={stat.id}
                  style={{
                    margin: "20px auto",
                    flexGrow: 1,
                    flexBasis: 0,
                    minWidth: 200,
                  }}
                >
                  <h3 style={{ margin: "0 0 10px 0" }}>{stat.value}</h3>
                  <div>{stat.labelText}</div>
                </div>
              ))}
            </div>
          )}
          {!!showAuthorsAndPublishDate && (
            <div
              style={{
                maxWidth: 750,
                display: "flex",
                justifyContent: "center",
                margin: "0 auto 20px auto",
                position: "relative",
                zIndex: 2,
              }}
            >
              <div style={{ margin: "auto 0" }}>
                {post.authors.map((author, i) => (
                  <div key={author.id}>
                    <AuthorLink to={`/${getAuthorLink(author.firstName)}`}>
                      {author.firstName} {author.lastName}
                    </AuthorLink>
                    {i !== post.authors.length - 1 && <span>,</span>}
                  </div>
                ))}
              </div>
              <div
                style={{ margin: "0 15px", borderRight: "1px solid white" }}
              ></div>
              <div style={{ margin: "auto 0" }}>
                {post.meta.firstPublishedAt}
              </div>
            </div>
          )}
        </div>
      </Section>
      <BlogPostContent>
        {!!displayFeaturedImage && (
          <div style={{ marginBottom: 20 }}>
            <GatsbyImage
              image={post.seo.image.gatsbyImageData}
              alt="Post feature"
            />
          </div>
        )}
        <ContentRenderer
          content={post.content}
          featuredImage={post.seo.image.gatsbyImageData}
        />
      </BlogPostContent>
      {!!footer && (
        <Section
          backgroundImage={footer.backgroundImage?.gatsbyImageData}
          backgroundOverlay={footer.backgroundOverlay}
          content={footer.content}
          contentOverflow={footer.contentOverlap}
          funkySwooshPosition={footer.funkySwooshPosition}
          funkySwooshStyle={footer.funkySwooshStyle}
          bottomSpacing={footer.bottomSpacing}
          textAlign={footer.textAlign}
        />
      )}
      <PostNavigator>
        <PostNavigatorInner>
          <div>
            {!!previousPost && (
              <Link to={previousPost.slug}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{previousPost.title}</span>
              </Link>
            )}
          </div>
          <Separator />
          <div>
            {!!nextPost && (
              <Link to={nextPost.slug}>
                <span>{nextPost.title}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            )}
          </div>
        </PostNavigatorInner>
      </PostNavigator>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery(
    $id: String
    $previousPostId: String
    $nextPostId: String
  ) {
    nextPost: datoCmsBlogPost(id: { eq: $nextPostId }) {
      slug
      title
    }
    previousPost: datoCmsBlogPost(id: { eq: $previousPostId }) {
      slug
      title
    }
    post: datoCmsBlogPost(id: { eq: $id }) {
      title
      slug
      category {
        title
        slugFriendlyName
      }
      tags {
        title
        slugFriendlyName
      }
      authors {
        id
        firstName
        lastName
      }
      footer {
        ...Link_Section
      }
      useFeaturedImageAtTopOfBlogPost
      headerStats {
        labelText
        value
        id
      }
      meta {
        firstPublishedAt(formatString: "MMMM D, YYYY")
      }
      seo {
        title
        description
        image {
          gatsbyImageData
        }
      }
      content {
        links {
          __typename
          ... on DatoCmsPage {
            ...Link_Page
          }
          ... on DatoCmsBlogPost {
            ...Link_BlogPost
          }
          ... on DatoCmsActiveCampaignForm {
            ...Link_ActiveCampaignForm
          }
        }
        blocks {
          __typename
          ... on DatoCmsYoutubeEmbed {
            id: originalId
            youtubeVideo
          }
          ... on DatoCmsNestedSection {
            ...Block_NestedSection
          }
          ... on DatoCmsBigQuoteSection {
            id: originalId
            background
            quoteText
            authorName
            authorJobTitle
          }
          ... on DatoCmsImage {
            ...Block_Image
          }
          ... on DatoCmsRichText {
            ...Block_RichText
          }
        }
        value
      }
    }
  }
`;

export default BlogPostTemplate;
